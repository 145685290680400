import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import {
  getAllWorkstationsByJobPositionId,
  uploadWorkstationImage,
} from "../../api/workstationApi";
import {
  createWorkstation,
  editWorkstation,
} from "../../api/workstationApi.js";
import ImageCard from "components/ImageCard/ImageCard.js";
import WorkstationCheckbox from "./WorkstationCheckBox";
import WorkstationImageRegister from "./WorkstationImageRegister";
import WorkstationComponentList from "./WorkstationComponentList";
import DropDownAttend from "components/DropDownAttend/DropdownAttend";
import DropDownIsTrue from "components/DropDownMenu/DropDownIsTrue";
import DropDownIsTrueEfficient from "components/DropDownMenu/DropDownIsTrueEfficient";
import DropDownOverTimeShift from "components/DropDownOverTImeShift/DropdownOverTimeShift";
import DropDownShortBreakShift from "components/DropDownShortBreakShift/DropdownShortBreakShift";
import { useLoginContext } from "context/LoginContext";

const styles = {
  title: {
    color: "black",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function WorkstationRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  jobPositionId,
  setRefreshWorkstationId,
  gheId,
  companyId,
  sectorId,
  workAreaId,
}) {
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);
  const [temperatureChecked, setTemperatureChecked] = useState(false);
  const [noiseChecked, setNoiseChecked] = useState(false);
  const [illuminanceChecked, setIlluminanceChecked] = useState(false);
  const [name, setName] = useState("");
  const [workingOurs, setWorkingOurs] = useState("");
  const [workersQuantity, setWorkersQuantity] = useState("");
  const [isOvertime, setIsOvertime] = useState("");
  const [overtimeShift, setOvertimeShift] = useState("");
  const [isShortBrake, setIsShortBrake] = useState("");
  const [shortBrakeShift, setShortBrakeShift] = useState("");
  const [isRotationalSystem, setIsRotationalSystem] = useState("");
  const [rotationalSystemFrequency, setRotationalSystemFrequency] = useState(
    ""
  );
  const [isRotationalEfficient, setIsRotationalEfficient] = useState("");
  const [whyRotationalEfficient, setWhyRotationalEfficient] = useState("");
  const [communicationFrequency, setCommunicationFrequency] = useState("");
  const [isOccupationalGymnastics, setIsOccupationalGymnastics] = useState("");
  const [isActivitiesProcedures, setIsActivitiesProcedures] = useState("");
  const [isProductionsGoals, setIsProductionsGoals] = useState("");
  const [isGoalsAchieved, setIsGoalsAchieved] = useState("");
  const [organizationalObservation, setOrganizationalObservation] = useState(
    ""
  );
  const [cognitiveObservation, setCognitiveObservation] = useState("");
  const [airSpeedBenchmark, setAirSpeedBenchmark] = useState("Não Aplicado");
  const [airSpeedAchieved, setAirSpeedAchieved] = useState("Não Aplicado");
  const [isAirSpeedAttend, setIsAirSpeedAttend] = useState("Não Aplicado");
  const [temperatureBenchmark, setTemperatureBenchmark] = useState(
    "Não Aplicado"
  );
  const [temperatureAchieved, setTemperatureAchieved] = useState(
    "Não Aplicado"
  );
  const [isTemperatureAttend, setIsTemperatureAttend] = useState(
    "Não Aplicado"
  );
  const [noiseBenchmark, setNoiseBenchmark] = useState("Não Aplicado");
  const [noiseAchieved, setNoiseAchieved] = useState("Não Aplicado");
  const [isNoiseAttend, setIsNoiseAttend] = useState("Não Aplicado");
  const [airHumidityBenchmark, setAirHumidityBenchmark] = useState(
    "Não Aplicado"
  );
  const [airHumidityAchieved, setAirHumidityAchieved] = useState(
    "Não Aplicado"
  );
  const [isAirHumidityAttend, setIsAirHumidityAttend] = useState(
    "Não Aplicado"
  );
  const [illuminanceBenchmark, setIlluminanceBenchmark] = useState(
    "Não Aplicado"
  );
  const [illuminanceAchieved, setIlluminanceAchieved] = useState(
    "Não Aplicado"
  );
  const [isIlluminanceAttend, setIsIlluminanceAttend] = useState(
    "Não Aplicado"
  );
  const [conclusion, setConclusion] = useState("");
  const [description, setDescription] = useState("");
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [workstationId, setWorkstationId] = useState("");
  const [workstationArray, setWorkstationArray] = useState("");
  const [observationVerbalization, setObservationVerbalization] = useState("");

  const [illuminanceObservation, setIlluminanceObservation] = useState("-");

  const [noiseObservation, setNoiseObservation] = useState("-");

  const [temperatureObservation, setTemperatureObservation] = useState("-");

  const { isReader } = useLoginContext();

  const classes = useStyles();

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);

      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  const handleSubmit = async () => {
    if (
      name === "" ||
      workingOurs === "" ||
      workersQuantity === "" ||
      description === "" ||
      isOvertime === "" ||
      overtimeShift === "" ||
      isShortBrake === "" ||
      shortBrakeShift === "" ||
      isRotationalSystem === "" ||
      rotationalSystemFrequency === "" ||
      isRotationalEfficient === "" ||
      whyRotationalEfficient === "" ||
      communicationFrequency === "" ||
      isOccupationalGymnastics === "" ||
      isActivitiesProcedures === "" ||
      isProductionsGoals === "" ||
      isGoalsAchieved === "" ||
      organizationalObservation === "" ||
      cognitiveObservation === ""
    ) {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await createWorkstation(
          name,
          workingOurs,
          workersQuantity,
          isOvertime,
          overtimeShift,
          isShortBrake,
          shortBrakeShift,
          isRotationalSystem,
          rotationalSystemFrequency,
          isRotationalEfficient,
          whyRotationalEfficient,
          communicationFrequency,
          isOccupationalGymnastics,
          isActivitiesProcedures,
          isProductionsGoals,
          isGoalsAchieved,
          organizationalObservation,
          cognitiveObservation,
          airSpeedBenchmark,
          airSpeedAchieved,
          isAirSpeedAttend,
          temperatureBenchmark,
          temperatureAchieved,
          isTemperatureAttend,
          noiseBenchmark,
          noiseAchieved,
          isNoiseAttend,
          airHumidityBenchmark,
          airHumidityAchieved,
          isAirHumidityAttend,
          illuminanceBenchmark,
          illuminanceAchieved,
          isIlluminanceAttend,
          description,
          jobPositionId,
          gheId,
          companyId,
          sectorId,
          workAreaId,
          conclusion,
          observationVerbalization,
          temperatureObservation,
          noiseObservation,
          illuminanceObservation
        );
        if (res) {
          for (const file of files) {
            await uploadWorkstationImage(file, res.id);
          }
        }
        setRefreshWorkstationId(true);
        setMessage("Posto de trabalho criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (
      name === "" ||
      workingOurs === "" ||
      workersQuantity === "" ||
      isOvertime === "" ||
      overtimeShift === "" ||
      description === "" ||
      isShortBrake === "" ||
      shortBrakeShift === "" ||
      isRotationalSystem === "" ||
      rotationalSystemFrequency === "" ||
      isRotationalEfficient === "" ||
      whyRotationalEfficient === "" ||
      communicationFrequency === "" ||
      isOccupationalGymnastics === "" ||
      isActivitiesProcedures === "" ||
      isProductionsGoals === "" ||
      isGoalsAchieved === "" ||
      organizationalObservation === "" ||
      cognitiveObservation === ""
    ) {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await editWorkstation(
          name,
          workingOurs,
          workersQuantity,
          isOvertime,
          overtimeShift,
          isShortBrake,
          shortBrakeShift,
          isRotationalSystem,
          rotationalSystemFrequency,
          isRotationalEfficient,
          whyRotationalEfficient,
          communicationFrequency,
          isOccupationalGymnastics,
          isActivitiesProcedures,
          isProductionsGoals,
          isGoalsAchieved,
          organizationalObservation,
          cognitiveObservation,
          airSpeedBenchmark,
          airSpeedAchieved,
          isAirSpeedAttend,
          temperatureBenchmark,
          temperatureAchieved,
          isTemperatureAttend,
          noiseBenchmark,
          noiseAchieved,
          isNoiseAttend,
          airHumidityBenchmark,
          airHumidityAchieved,
          isAirHumidityAttend,
          illuminanceBenchmark,
          illuminanceAchieved,
          isIlluminanceAttend,
          description,
          workstationId,
          conclusion,
          observationVerbalization,
          temperatureObservation,
          noiseObservation,
          illuminanceObservation
        );
        if (res) {
          for (const file of files) {
            await uploadWorkstationImage(file, res.id);
          }
        }

        setMessage("Posto de trabalho editado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshWorkstationId(true);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllWorkstationsByJobPositionId(jobPositionId);
      setWorkstationArray(data);
      setRefresh(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    refresh ? setRefreshWorkstationId(true) : setRefreshWorkstationId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <CardBody>
      <WorkstationComponentList
        setRefresh={setRefresh}
        setEdit={setEdit}
        workstations={workstationArray}
        setName={setName}
        setWorkingOurs={setWorkingOurs}
        setWorkersQuantity={setWorkersQuantity}
        setIsOvertime={setIsOvertime}
        setOvertimeShift={setOvertimeShift}
        setIsShortBrake={setIsShortBrake}
        setShortBrakeShift={setShortBrakeShift}
        setIsRotationalSystem={setIsRotationalSystem}
        setRotationalSystemFrequency={setRotationalSystemFrequency}
        setIsRotationalEfficient={setIsRotationalEfficient}
        setWhyRotationalEfficient={setWhyRotationalEfficient}
        setCommunicationFrequency={setCommunicationFrequency}
        setIsOccupationalGymnastics={setIsOccupationalGymnastics}
        setIsActivitiesProcedures={setIsActivitiesProcedures}
        setIsProductionsGoals={setIsProductionsGoals}
        setIsGoalsAchieved={setIsGoalsAchieved}
        setOrganizationalObservation={setOrganizationalObservation}
        setCognitiveObservation={setCognitiveObservation}
        setAirSpeedBenchmark={setAirSpeedBenchmark}
        setAirSpeedAchieved={setAirSpeedAchieved}
        setIsAirSpeedAttend={setIsAirSpeedAttend}
        setTemperatureBenchmark={setTemperatureBenchmark}
        setTemperatureAchieved={setTemperatureAchieved}
        setIsTemperatureAttend={setIsTemperatureAttend}
        setNoiseBenchmark={setNoiseBenchmark}
        setNoiseAchieved={setNoiseAchieved}
        setIsNoiseAttend={setIsNoiseAttend}
        setAirHumidityBenchmark={setAirHumidityBenchmark}
        setAirHumidityAchieved={setAirHumidityAchieved}
        setIsAirHumidityAttend={setIsAirHumidityAttend}
        setIlluminanceBenchmark={setIlluminanceBenchmark}
        setIlluminanceAchieved={setIlluminanceAchieved}
        setIsIlluminanceAttend={setIsIlluminanceAttend}
        setWorkstationId={setWorkstationId}
        setDescription={setDescription}
        setConclusion={setConclusion}
        setObservationVerbalization={setObservationVerbalization}
        setIlluminanceObservation={setIlluminanceObservation}
        setNoiseObservation={setNoiseObservation}
        setTemperatureObservation={setTemperatureObservation}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome do posto de trabalho"
            name="name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <TextField
            multiline
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="outlined"
            id="description"
            label="Descrição"
            name="description"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={workingOurs}
            onChange={(event) => setWorkingOurs(event.target.value)}
            variant="outlined"
            id="working_ours"
            label="Jornada de trabalho"
            name="working_ours"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={workersQuantity}
            onChange={(event) => setWorkersQuantity(event.target.value)}
            variant="outlined"
            id="workers_quantity"
            label="Número de funcionários"
            name="workers_quantity"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"Fazem hora extra?"}
              itemSelected={isOvertime}
              itemSelector={setIsOvertime}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownOverTimeShift
              overTime={overtimeShift}
              setOverTime={setOvertimeShift}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"Fazem pausas curtas?"}
              itemSelected={isShortBrake}
              itemSelector={setIsShortBrake}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownShortBreakShift
              shift={shortBrakeShift}
              setShift={setShortBrakeShift}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"Fazem rodízio?"}
              itemSelected={isRotationalSystem}
              itemSelector={setIsRotationalSystem}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={rotationalSystemFrequency}
            onChange={(event) =>
              setRotationalSystemFrequency(event.target.value)
            }
            variant="outlined"
            id="rotational_system_frequency"
            label="Com qual atividade?"
            name="rotational_system_frequency"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrueEfficient
              filterName={"Rodízio é eficiente?"}
              itemSelected={isRotationalEfficient}
              itemSelector={setIsRotationalEfficient}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={whyRotationalEfficient}
            onChange={(event) => setWhyRotationalEfficient(event.target.value)}
            variant="outlined"
            id="why_rotational_efficient"
            label="Por que é eficiente?"
            name="why_rotational_efficient"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={communicationFrequency}
            onChange={(event) => setCommunicationFrequency(event.target.value)}
            variant="outlined"
            id="communication_frequency"
            label="Frequência de comunicação com a chefia"
            name="communication_frequency"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"Há programa de Ginástica Laboral?"}
              itemSelected={isOccupationalGymnastics}
              itemSelector={setIsOccupationalGymnastics}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"Há procedimentos na execução de atividades?"}
              itemSelected={isActivitiesProcedures}
              itemSelector={setIsActivitiesProcedures}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"Há meta de produção?"}
              itemSelected={isProductionsGoals}
              itemSelector={setIsProductionsGoals}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-5px" }}>
            <DropDownIsTrue
              filterName={"As metas são atingidas?"}
              itemSelected={isGoalsAchieved}
              itemSelector={setIsGoalsAchieved}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            multiline
            value={organizationalObservation}
            onChange={(event) =>
              setOrganizationalObservation(event.target.value)
            }
            variant="outlined"
            id="organizational_observation"
            label="Observações organizacionais"
            name="organizational_observation"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            multiline
            value={cognitiveObservation}
            onChange={(event) => setCognitiveObservation(event.target.value)}
            variant="outlined"
            id="cognitive_observation"
            label="Observações cognitivas/psicossociais?"
            name="cognitive_observation"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            multiline
            value={observationVerbalization}
            onChange={(event) =>
              setObservationVerbalization(event.target.value)
            }
            variant="outlined"
            id="observationVerbalization"
            label="Observação/Verbalização?"
            name="observationVerbalization"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={6} sm={3} md={3}>
          <WorkstationCheckbox
            checked={temperatureChecked}
            setCheck={setTemperatureChecked}
            title="Conforto Térmico"
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3}>
          <WorkstationCheckbox
            checked={noiseChecked}
            setCheck={setNoiseChecked}
            title="Ruído"
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3}>
          <WorkstationCheckbox
            checked={illuminanceChecked}
            setCheck={setIlluminanceChecked}
            title="Iluminância"
          />
        </GridItem>

        {temperatureChecked && (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <h3 className={classes.title}>Conforto Térmico</h3>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                multiline
                value={temperatureBenchmark}
                onChange={(event) =>
                  setTemperatureBenchmark(event.target.value)
                }
                variant="outlined"
                id="temperature_benchmark"
                label="Valor referente"
                name="temperature_benchmark"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                multiline
                value={temperatureAchieved}
                onChange={(event) => setTemperatureAchieved(event.target.value)}
                variant="outlined"
                id="temperature_achieved"
                label="Valor obtido?"
                name="temperature_achieved"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div style={{ marginTop: "-5px" }}>
                <DropDownAttend
                  attend={isTemperatureAttend}
                  setAttend={setIsTemperatureAttend}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                multiline
                value={temperatureObservation}
                onChange={(event) =>
                  setTemperatureObservation(event.target.value)
                }
                variant="outlined"
                id="temperature_observation"
                label="Observação"
                name="temperature_observation"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </GridItem>
          </>
        )}
        {noiseChecked && (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <h3 className={classes.title}>Ruído</h3>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                multiline
                value={noiseBenchmark}
                onChange={(event) => setNoiseBenchmark(event.target.value)}
                variant="outlined"
                id="noise_benchmark"
                label="Valor referente"
                name="noise_benchmark"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                multiline
                value={noiseAchieved}
                onChange={(event) => setNoiseAchieved(event.target.value)}
                variant="outlined"
                id="noise_achieved"
                label="Valor obtido?"
                name="noise_achieved"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div style={{ marginTop: "-5px" }}>
                <DropDownAttend
                  attend={isNoiseAttend}
                  setAttend={setIsNoiseAttend}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                multiline
                value={noiseObservation}
                onChange={(event) => setNoiseObservation(event.target.value)}
                variant="outlined"
                id="noise_observation"
                label="Observação"
                name="noise_observation"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </GridItem>
          </>
        )}
        {illuminanceChecked && (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <h3 className={classes.title}>Iluminância</h3>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                multiline
                value={illuminanceBenchmark}
                onChange={(event) =>
                  setIlluminanceBenchmark(event.target.value)
                }
                variant="outlined"
                id="illuminance_benchmark"
                label="Valor referente"
                name="illuminance_benchmark"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                multiline
                value={illuminanceAchieved}
                onChange={(event) => setIlluminanceAchieved(event.target.value)}
                variant="outlined"
                id="illuminance_achieved"
                label="Valor obtido?"
                name="illuminance_achieved"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div style={{ marginTop: "-5px" }}>
                <DropDownAttend
                  attend={isIlluminanceAttend}
                  setAttend={setIsIlluminanceAttend}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                multiline
                value={illuminanceObservation}
                onChange={(event) =>
                  setIlluminanceObservation(event.target.value)
                }
                variant="outlined"
                id="illuminance_observation"
                label="Observação"
                name="illuminance_observation"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </GridItem>
          </>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            multiline
            value={conclusion}
            onChange={(event) => setConclusion(event.target.value)}
            variant="outlined"
            id="conclusion"
            label="Conclusão"
            name="conclusion"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} />

        {preview.length > 0 &&
          preview
            .filter((preview) => preview.blob !== null)
            .map((preview) => {
              return (
                <ImageCard
                  key={preview.pos}
                  preview={preview}
                  removeFile={removeFile}
                />
              );
            })}
        {!edit && (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  variant="contained"
                  color="primary"
                  component="label"
                >
                  + Adicionar imagens
                  <input
                    onChange={handleUploadMultipleFiles}
                    type="file"
                    className="form-control"
                    multiple
                    hidden
                  />
                </RegularButton>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  color="success"
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Criar Posto de Trabalho
                </RegularButton>
              </div>
            </GridItem>
          </>
        )}
        {edit && (
          <WorkstationImageRegister
            workstationId={workstationId}
            setEdit={setEdit}
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            handleSubmitWorkstation={handleEdit}
            setChecked={setChecked}
          />
        )}
      </GridContainer>
    </CardBody>
  );
}
