import GridContainer from "components/Grid/GridContainer";
import React from "react";
import BriefAndBestCard from "../BriefAndBestCard";
import shoulders1 from "../../../assets/img/shoulders1.png";
import shoulders1Right from "../../../assets/img/shoulders1Right.png";
import shoulders2 from "../../../assets/img/shoulders2.png";
import shoulders3 from "../../../assets/img/shoulders3.png";
import shoulders6 from "../../../assets/img/shoulders6.png";
import shoulders7 from "../../../assets/img/shoulders7.png";
import shoulders3left from "../../../assets/img/shoulder3Left.png";
import BriefAndBestStrengthCard from "../BriefAndBestStrengthCard";
import elbowStrength1 from "../../../assets/img/elbowStrength1.png";
import elbowStrength2 from "../../../assets/img/elbowStrength2.png";

import { useBriefAndBestContext } from "../../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestShoulder() {
  const {
    rightShoulderPostureCounter,
    setRightShoulderPostureCounter,
    leftShoulderPostureCounter,
    setLeftShoulderPostureCounter,
    bothShouldersStrengthCounter,
    setBothShouldersStrengthCounter,
    rightShoulderStrengthCounter,
    setRightShoulderStrengthCounter,
    leftShoulderStrengthCounter,
    setLeftShoulderStrengthCounter,
    bothShouldersDurationCounter,
    setBothShouldersDurationCounter,
    rightShoulderDurationCounter,
    setRightShoulderDurationCounter,
    leftShoulderDurationCounter,
    setLeftShoulderDurationCounter,
    bothShouldersFrequencyCounter,
    setBothShouldersFrequencyCounter,
    rightShoulderFrequencyCounter,
    setRightShoulderFrequencyCounter,
    leftShoulderFrequencyCounter,
    setLeftShoulderFrequencyCounter,

    setLeftShoulderFlexion,
    setRightShoulderFlexion,
    setLeftShoulderAbduction,
    setRightShoulderAbduction,
    setLeftShoulderElevation,
    setRightShoulderElevation,
    setLeftShoulderExtension,
    setRightShoulderExtension,
    setLeftShoulderStrength,
    setLeftShoulderDuration,
    setLeftShoulderFrequency,
    setRightShoulderStrength,
    setRightShoulderDuration,
    setRightShoulderFrequency,
    setBothShoulderStrength,
    setBothShoulderDuration,
    setBothShoulderFrequency,
    leftShoulderFlexion,
    rightShoulderFlexion,
    leftShoulderAbduction,
    rightShoulderAbduction,
    leftShoulderElevation,
    rightShoulderElevation,
    leftShoulderExtension,
    rightShoulderExtension,
    leftShoulderStrength,
    leftShoulderDuration,
    leftShoulderFrequency,
    rightShoulderStrength,
    rightShoulderDuration,
    rightShoulderFrequency,
    bothShoulderStrength,
    bothShoulderDuration,
    bothShoulderFrequency,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={6} md={6}>
          <div style={{ padding: "5%", paddingTop: "0" }}>
            <GridContainer>
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftShoulderFlexion}
                check={leftShoulderFlexion}
                functionPosture={setLeftShoulderPostureCounter}
                counterPosture={leftShoulderPostureCounter}
                name={"Ombro esquerdo"}
                title={"Ombro esquerdo"}
                picture={shoulders1Right}
                subtitle={"flexão >= 45º"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftShoulderAbduction}
                check={leftShoulderAbduction}
                functionPosture={setLeftShoulderPostureCounter}
                counterPosture={leftShoulderPostureCounter}
                name={"Ombro esquerdo"}
                title={"Ombro esquerdo"}
                picture={shoulders3left}
                subtitle={"abdução >= 45º"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftShoulderElevation}
                check={leftShoulderElevation}
                functionPosture={setLeftShoulderPostureCounter}
                counterPosture={leftShoulderPostureCounter}
                name={"Ombro esquerdo"}
                title={"Ombro esquerdo"}
                picture={shoulders3}
                subtitle={"elevação"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftShoulderExtension}
                check={leftShoulderExtension}
                functionPosture={setLeftShoulderPostureCounter}
                counterPosture={leftShoulderPostureCounter}
                name={"Ombro esquerdo"}
                title={"Ombro esquerdo"}
                picture={shoulders7}
                subtitle={"Extensão"}
              />
            </GridContainer>
          </div>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <div style={{ padding: "5%", paddingTop: "0" }}>
            <GridContainer>
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightShoulderFlexion}
                check={rightShoulderFlexion}
                functionPosture={setRightShoulderPostureCounter}
                counterPosture={rightShoulderPostureCounter}
                name={"Ombro direito"}
                title={"Ombro direito"}
                picture={shoulders1}
                subtitle={"flexão >= 45º"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightShoulderAbduction}
                check={rightShoulderAbduction}
                functionPosture={setRightShoulderPostureCounter}
                counterPosture={rightShoulderPostureCounter}
                name={"Ombro direito"}
                title={"Ombro direito"}
                picture={shoulders2}
                subtitle={"abdução >= 45º"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightShoulderElevation}
                check={rightShoulderElevation}
                functionPosture={setRightShoulderPostureCounter}
                counterPosture={rightShoulderPostureCounter}
                name={"Ombro direito"}
                title={"Ombro direito"}
                picture={shoulders3}
                subtitle={"elevação"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightShoulderExtension}
                check={rightShoulderExtension}
                functionPosture={setRightShoulderPostureCounter}
                counterPosture={rightShoulderPostureCounter}
                name={"Ombro direito"}
                title={"Ombro direito"}
                picture={shoulders6}
                subtitle={"Extensão"}
              />
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ padding: "15%", paddingTop: "0" }}>
          <GridContainer>
            <BriefAndBestStrengthCard
              line={2}
              lineMid={12}
              functionCheckStrength={setLeftShoulderStrength}
              checkStrength={leftShoulderStrength}
              functionCheckDuration={setLeftShoulderDuration}
              checkDuration={leftShoulderDuration}
              functionCheckFrequency={setLeftShoulderFrequency}
              checkFrequency={leftShoulderFrequency}
              functionStrength={setLeftShoulderStrengthCounter}
              counterStrength={leftShoulderStrengthCounter}
              functionDuration={setLeftShoulderDurationCounter}
              counterDuration={leftShoulderDurationCounter}
              functionFrequency={setLeftShoulderFrequencyCounter}
              counterFrequency={leftShoulderFrequencyCounter}
              frequency={">=2/min"}
              duration={">=10s"}
              name={"Ombro esquerdo"}
              subtitle={"Força igual ou maior que 4,5kg"}
              title={"Ombro esquerdo"}
              picture={elbowStrength1}
            />

            <GridItem xs={12} sm={12} md={2} />

            <BriefAndBestStrengthCard
              line={2}
              lineMid={12}
              functionCheckStrength={setBothShoulderStrength}
              checkStrength={bothShoulderStrength}
              functionCheckDuration={setBothShoulderDuration}
              checkDuration={bothShoulderDuration}
              functionCheckFrequency={setBothShoulderFrequency}
              checkFrequency={bothShoulderFrequency}
              functionStrength={setBothShouldersStrengthCounter}
              counterStrength={bothShouldersStrengthCounter}
              functionDuration={setBothShouldersDurationCounter}
              counterDuration={bothShouldersDurationCounter}
              functionFrequency={setBothShouldersFrequencyCounter}
              counterFrequency={bothShouldersFrequencyCounter}
              frequency={">=2/min"}
              duration={">=10s"}
              name={"Ambos Ombros"}
              subtitle={"Força igual ou maior que 6,8kg"}
              title={"Ambos Ombros"}
              picture={elbowStrength2}
            />

            <GridItem xs={12} sm={12} md={2} />

            <BriefAndBestStrengthCard
              line={2}
              lineMid={12}
              functionCheckStrength={setRightShoulderStrength}
              checkStrength={rightShoulderStrength}
              functionCheckDuration={setRightShoulderDuration}
              checkDuration={rightShoulderDuration}
              functionCheckFrequency={setRightShoulderFrequency}
              checkFrequency={rightShoulderFrequency}
              functionStrength={setRightShoulderStrengthCounter}
              counterStrength={rightShoulderStrengthCounter}
              functionDuration={setRightShoulderDurationCounter}
              counterDuration={rightShoulderDurationCounter}
              functionFrequency={setRightShoulderFrequencyCounter}
              counterFrequency={rightShoulderFrequencyCounter}
              frequency={">=2/min"}
              duration={">=10s"}
              name={"Ombro direito"}
              subtitle={"Força igual ou maior que 4,5kg"}
              title={"Ombro direito"}
              picture={elbowStrength1}
            />
          </GridContainer>
        </div>
      </GridItem>
    </div>
  );
}
