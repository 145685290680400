import React, { useState, useEffect } from "react";
// @material-ui/core components
// core components

import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import ImageCard from "components/ImageCard/ImageCard";
import BriefAndBest from "../../views/BriefAndBest/BriefAndBestParts";
import { useBriefAndBestContext } from "../../context/BriefAndBestContext.js";
import {
  createStepEvaluation,
  editStepEvaluation,
  uploadEvaluationImage,
  getAllEvaluationsByStepIdAndType,
  getAllEvaluationsImages,
  deleteEvaluation,
} from "../../api/evaluationApi.js";
import HumanBody from "components/HumanBodyReport/HumanBody";
import StepEvaluationImage from "./StepEvaluationImage";
import BriefAndBestNPREvaluation from "components/BriefAndBest/BriefAndBestNPREvaluation";
import StepEvaluationImageSide from "./StepEvaluationImageSide";
import DeleteDialogEvaluation from "components/DeleteDialog/DeleteDialogEvaluation";
import { useLoginContext } from "context/LoginContext";
import TabelaRisco from "./RiskTable";

export default function StepEvaluationView({
  stepId,
  evaluationType,
  setOpen,
  setMessage,
  setSeverity,
  activityId,
  companyId,
  gheId,
  jobPositionId,
  sectorId,
  areaId,
  workstationId,
  setOpenBackDrop,
  openBackDrop,
}) {
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);
  const [rightHand, setRightHand] = useState(0);
  const [leftHand, setLeftHand] = useState(0);
  const [rightShoulder, setRightShoulder] = useState(0);
  const [leftShoulder, setLeftShoulder] = useState(0);
  const [rightElbow, setRightElbow] = useState(0);
  const [leftElbow, setLeftElbow] = useState(0);
  const [neck, setNeck] = useState(0);
  const [back, setBack] = useState(0);
  const [legs, setLegs] = useState(0);
  const [npr, setNpr] = useState(0);
  const [edit, setEdit] = useState(true);
  const [editImage, setEditImage] = useState(false);
  const [buttonState, setButtonState] = useState("create");
  const [buttonTitle, setButtonTitle] = useState("Criar Avaliação");
  const [evaluationId, setEvaluationId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [image, setImage] = useState("");
  const [, setDataEvaluation] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [stepFrequencyData, setStepFrequencyData] = useState("");

  const { isReader } = useLoginContext();

  const {
    rightHandResults,
    leftHandResults,
    rightShoulderResults,
    leftShoulderResults,
    rightElbowResults,
    leftElbowResults,
    neckResults,
    backResults,
    legsResults,
    totalResults,
    stepFrequency,
    stepPosition,
    setRightHandResults,
    setLeftHandResults,
    setRightShoulderResults,
    setLeftShoulderResults,
    setRightElbowResults,
    setLeftElbowResults,
    setNeckResults,
    setBackResults,
    setLegsResults,
    setTotalResults,
    setStepFrequency,

    setRightHandPostureCounter,
    setLeftHandPostureCounter,
    setRightElbowPostureCounter,
    setRightShoulderPostureCounter,
    setLeftElbowPostureCounter,
    setLeftShoulderPostureCounter,
    setNeckPostureCounter,
    setBackPostureCounter,
    setLegsPostureCounter,
    setRightHandStrengthCounter,
    setLeftHandStrengthCounter,
    setRightElbowStrengthCounter,
    setBothElbowStrengthCounter,
    setBothShouldersStrengthCounter,
    setRightShoulderStrengthCounter,
    setLeftElbowStrengthCounter,
    setLeftShoulderStrengthCounter,
    setNeckStrengthCounter,
    setBackStrengthCounter,
    setLegsStrengthCounter,
    setRightHandDurationCounter,
    setLeftHandDurationCounter,
    setRightElbowDurationCounter,
    setBothElbowDurationCounter,
    setBothShouldersDurationCounter,
    setRightShoulderDurationCounter,
    setLeftElbowDurationCounter,
    setLeftShoulderDurationCounter,
    setNeckDurationCounter,
    setBackDurationCounter,
    setLegsDurationCounter,
    setRightHandFrequencyCounter,
    setLeftHandFrequencyCounter,
    setRightElbowFrequencyCounter,
    setBothElbowFrequencyCounter,
    setBothShouldersFrequencyCounter,
    setRightShoulderFrequencyCounter,
    setLeftElbowFrequencyCounter,
    setLeftShoulderFrequencyCounter,
    setNeckFrequencyCounter,
    setBackFrequencyCounter,
    setLegsFrequencyCounter,
    setVibrationCounter,
    setLowTemperatureCounter,
    setSoftTissueCompressionCounter,
    setImpactStressCounter,
    setGloveIssuesCounter,

    leftHandExtension,
    leftHandFlexion,
    leftHandUlnar,
    leftHandRadial,
    rightHandExtension,
    rightHandFlexion,
    rightHandUlnar,
    rightHandRadial,
    leftHandGripStrength,
    leftHandGripDuration,
    leftHandGripFrequency,
    leftHandHoldStrength,
    leftHandHoldDuration,
    leftHandHoldFrequency,
    leftHandClampStrength,
    leftHandClampDuration,
    leftHandClampFrequency,
    rightHandGripStrength,
    rightHandGripDuration,
    rightHandGripFrequency,
    rightHandHoldStrength,
    rightHandHoldDuration,
    rightHandHoldFrequency,
    rightHandClampStrength,
    rightHandClampDuration,
    rightHandClampFrequency,
    setLeftHandExtension,
    setLeftHandFlexion,
    setLeftHandUlnar,
    setLeftHandRadial,
    setRightHandExtension,
    setRightHandFlexion,
    setRightHandUlnar,
    setRightHandRadial,
    setLeftHandGripStrength,
    setLeftHandGripDuration,
    setLeftHandGripFrequency,
    setLeftHandHoldStrength,
    setLeftHandHoldDuration,
    setLeftHandHoldFrequency,
    setLeftHandClampStrength,
    setLeftHandClampDuration,
    setLeftHandClampFrequency,
    setRightHandGripStrength,
    setRightHandGripDuration,
    setRightHandGripFrequency,
    setRightHandHoldDuration,
    setRightHandHoldStrength,
    setRightHandHoldFrequency,
    setRightHandClampStrength,
    setRightHandClampDuration,
    setRightHandClampFrequency,
    timeActivity,
    setLeftElbowRotation,
    setRightElbowRotation,
    setLeftElbowExtension,
    setRightElbowExtension,
    setLeftElbowStrength,
    setLeftElbowDuration,
    setLeftElbowFrequency,
    setRightElbowStrength,
    setRightElbowDuration,
    setRightElbowFrequency,
    setBothElbowStrength,
    setBothElbowDuration,
    setBothElbowFrequency,
    leftElbowRotation,
    rightElbowRotation,
    leftElbowExtension,
    rightElbowExtension,
    leftElbowStrength,
    leftElbowDuration,
    leftElbowFrequency,
    rightElbowStrength,
    rightElbowDuration,
    rightElbowFrequency,
    bothElbowStrength,
    bothElbowDuration,
    bothElbowFrequency,
    setLeftShoulderFlexion,
    setRightShoulderFlexion,
    setLeftShoulderAbduction,
    setRightShoulderAbduction,
    setLeftShoulderElevation,
    setRightShoulderElevation,
    setLeftShoulderExtension,
    setRightShoulderExtension,
    setLeftShoulderStrength,
    setLeftShoulderDuration,
    setLeftShoulderFrequency,
    setRightShoulderStrength,
    setRightShoulderDuration,
    setRightShoulderFrequency,
    setBothShoulderStrength,
    setBothShoulderDuration,
    setBothShoulderFrequency,
    leftShoulderFlexion,
    rightShoulderFlexion,
    leftShoulderAbduction,
    rightShoulderAbduction,
    leftShoulderElevation,
    rightShoulderElevation,
    leftShoulderExtension,
    rightShoulderExtension,
    leftShoulderStrength,
    leftShoulderDuration,
    leftShoulderFrequency,
    rightShoulderStrength,
    rightShoulderDuration,
    rightShoulderFrequency,
    bothShoulderStrength,
    bothShoulderDuration,
    bothShoulderFrequency,
    setNeckFlexion,
    setNeckSideFlexion,
    setNeckExtension,
    setNeckRotation,
    setNeckStrength,
    setNeckDuration,
    setNeckFrequency,
    neckFlexion,
    neckSideFlexion,
    neckExtension,
    neckRotation,
    neckStrength,
    neckDuration,
    neckFrequency,
    setBackFlexion,
    setBackRotation,
    setBackSideFlexion,
    setBackExtension,
    setBackNoSupport,
    setBackStrength,
    setBackDuration,
    setBackFrequency,
    backFlexion,
    backRotation,
    backSideFlexion,
    backExtension,
    backNoSupport,
    backStrength,
    backDuration,
    backFrequency,
    setLegsCrouching,
    setLegsKneeling,
    setLegsNoSupport,
    setLegsStrength,
    setLegsDuration,
    setLegsFrequency,
    legsCrouching,
    legsKneeling,
    legsNoSupport,
    legsStrength,
    legsDuration,
    legsFrequency,
    setVibration,
    setLowTemperature,
    setSoftTissueCompression,
    setImpactStress,
    setGloveIssue,
    vibration,
    lowTemperature,
    softTissueCompression,
    impactStress,
    gloveIssue,
    setTimeActivity,
    lift,
    setLift,
    push,
    setPush,
    pull,
    setPull,
    load,
    setLoad,
  } = useBriefAndBestContext();

  const handleEdit = () => {
    setRightHandPostureCounter(0);
    setLeftHandPostureCounter(0);
    setRightElbowPostureCounter(0);
    setRightShoulderPostureCounter(0);
    setLeftElbowPostureCounter(0);
    setLeftShoulderPostureCounter(0);
    setNeckPostureCounter(0);
    setBackPostureCounter(0);
    setLegsPostureCounter(0);
    setRightHandStrengthCounter(0);
    setLeftHandStrengthCounter(0);
    setRightElbowStrengthCounter(0);
    setBothElbowStrengthCounter(0);
    setBothShouldersStrengthCounter(0);
    setRightShoulderStrengthCounter(0);
    setLeftElbowStrengthCounter(0);
    setLeftShoulderStrengthCounter(0);
    setNeckStrengthCounter(0);
    setBackStrengthCounter(0);
    setLegsStrengthCounter(0);
    setRightHandDurationCounter(0);
    setLeftHandDurationCounter(0);
    setRightElbowDurationCounter(0);
    setBothElbowDurationCounter(0);
    setBothShouldersDurationCounter(0);
    setRightShoulderDurationCounter(0);
    setLeftElbowDurationCounter(0);
    setLeftShoulderDurationCounter(0);
    setNeckDurationCounter(0);
    setBackDurationCounter(0);
    setLegsDurationCounter(0);
    setRightHandFrequencyCounter(0);
    setLeftHandFrequencyCounter(0);
    setRightElbowFrequencyCounter(0);
    setBothElbowFrequencyCounter(0);
    setBothShouldersFrequencyCounter(0);
    setRightShoulderFrequencyCounter(0);
    setLeftElbowFrequencyCounter(0);
    setLeftShoulderFrequencyCounter(0);
    setNeckFrequencyCounter(0);
    setBackFrequencyCounter(0);
    setLegsFrequencyCounter(0);
    setVibrationCounter(0);
    setLowTemperatureCounter(0);
    setSoftTissueCompressionCounter(0);
    setImpactStressCounter(0);
    setGloveIssuesCounter(0);
    setRightHandResults(0);
    setLeftHandResults(0);
    setRightShoulderResults(0);
    setLeftShoulderResults(0);
    setRightElbowResults(0);
    setLeftElbowResults(0);
    setNeckResults(0);
    setBackResults(0);
    setLegsResults(0);
    setTotalResults(0);
    setStepFrequency(0);
    setRightHand(0);
    setLeftHand(0);
    setRightElbow(0);
    setLeftElbow(0);
    setRightShoulder(0);
    setLeftShoulder(0);
    setNeck(0);
    setLegs(0);
    setBack(0);
    setNpr(0);
    setEdit(true);
    setEditImage(false);
    setOpenBackDrop(true);
    const fetch = async () => {
      const data = await getAllEvaluationsByStepIdAndType(
        evaluationType,
        stepId
      );
      setOpenBackDrop(false);
      setTimeActivity(Number(data[0].time_activity));
      setVibration(data[0].vibration);
      setLowTemperature(data[0].low_temperature);
      setSoftTissueCompression(data[0].soft_tissue_compression);
      setImpactStress(data[0].impact_stress);
      setGloveIssue(data[0].glove_issue);
      setLeftHandExtension(data[0].left_hand_extension);
      setLeftHandFlexion(data[0].left_hand_flexion);
      setLeftHandUlnar(data[0].left_hand_ulnar);
      setLeftHandRadial(data[0].left_hand_radial);
      setRightHandExtension(data[0].right_hand_extension);
      setRightHandFlexion(data[0].right_hand_flexion);
      setRightHandUlnar(data[0].right_hand_ulnar);
      setRightHandRadial(data[0].right_hand_radial);
      setLeftHandGripStrength(data[0].left_hand_grip_strength);
      setLeftHandGripDuration(data[0].left_hand_grip_duration);
      setLeftHandGripFrequency(data[0].left_hand_grip_frequency);
      setLeftHandHoldStrength(data[0].left_hand_hold_strength);
      setLeftHandHoldDuration(data[0].left_hand_hold_duration);
      setLeftHandHoldFrequency(data[0].left_hand_hold_frequency);
      setLeftHandClampStrength(data[0].left_hand_clamp_strength);
      setLeftHandClampDuration(data[0].left_hand_clamp_duration);
      setLeftHandClampFrequency(data[0].left_hand_clamp_frequency);
      setRightHandGripStrength(data[0].right_hand_grip_strength);
      setRightHandGripDuration(data[0].right_hand_grip_duration);
      setRightHandGripFrequency(data[0].right_hand_grip_frequency);
      setRightHandHoldDuration(data[0].right_hand_hold_duration);
      setRightHandHoldStrength(data[0].right_hand_hold_strength);
      setRightHandHoldFrequency(data[0].right_hand_hold_frequency);
      setRightHandClampStrength(data[0].right_hand_clamp_strength);
      setRightHandClampDuration(data[0].right_hand_clamp_duration);
      setRightHandClampFrequency(data[0].right_hand_clamp_frequency);
      setLeftElbowRotation(data[0].left_elbow_rotation);
      setRightElbowRotation(data[0].right_elbow_rotation);
      setLeftElbowExtension(data[0].left_elbow_extension);
      setRightElbowExtension(data[0].right_elbow_extension);
      setLeftElbowStrength(data[0].left_elbow_strength);
      setLeftElbowDuration(data[0].left_elbow_duration);
      setLeftElbowFrequency(data[0].left_elbow_frequency);
      setRightElbowStrength(data[0].right_elbow_strength);
      setRightElbowDuration(data[0].right_elbow_duration);
      setRightElbowFrequency(data[0].right_elbow_frequency);
      setBothElbowStrength(data[0].both_elbow_strength);
      setBothElbowDuration(data[0].both_elbow_duration);
      setBothElbowFrequency(data[0].both_elbow_frequency);
      setLeftShoulderFlexion(data[0].left_shoulder_flexion);
      setRightShoulderFlexion(data[0].right_shoulder_flexion);
      setLeftShoulderAbduction(data[0].left_shoulder_abduction);
      setRightShoulderAbduction(data[0].right_shoulder_abduction);
      setLeftShoulderElevation(data[0].left_shoulder_elevation);
      setRightShoulderElevation(data[0].right_shoulder_elevation);
      setLeftShoulderExtension(data[0].left_shoulder_extension);
      setRightShoulderExtension(data[0].right_shoulder_extension);
      setLeftShoulderStrength(data[0].left_shoulder_strength);
      setLeftShoulderDuration(data[0].left_shoulder_duration);
      setLeftShoulderFrequency(data[0].left_shoulder_frequency);
      setRightShoulderStrength(data[0].right_shoulder_strength);
      setRightShoulderDuration(data[0].right_shoulder_duration);
      setRightShoulderFrequency(data[0].right_shoulder_frequency);
      setBothShoulderStrength(data[0].both_shoulder_strength);
      setBothShoulderDuration(data[0].both_shoulder_duration);
      setBothShoulderFrequency(data[0].both_shoulder_frequency);
      setNeckFlexion(data[0].neck_flexion);
      setNeckSideFlexion(data[0].neck_side_flexion);
      setNeckExtension(data[0].neck_extension);
      setNeckRotation(data[0].neck_rotation);
      setNeckStrength(data[0].neck_strength);
      setNeckDuration(data[0].neck_duration);
      setNeckFrequency(data[0].neck_frequency);
      setBackFlexion(data[0].back_flexion);
      setBackRotation(data[0].back_rotation);
      setBackSideFlexion(data[0].back_side_flexion);
      setBackExtension(data[0].back_extension);
      setBackNoSupport(data[0].back_no_support);
      setBackStrength(data[0].back_strength);
      setBackDuration(data[0].back_duration);
      setBackFrequency(data[0].back_frequency);
      setLegsCrouching(data[0].legs_crouching);
      setLegsKneeling(data[0].legs_kneeling);
      setLegsNoSupport(data[0].legs_no_support);
      setLegsStrength(data[0].legs_strength);
      setLegsDuration(data[0].legs_duration);
      setLegsFrequency(data[0].legs_frequency);
      setLift(data[0].lift);
      setPull(data[0].pull);
      setPush(data[0].push);
      setLoad(data[0].load);
    };
    fetch();
  };

  const handleEditImage = () => {
    setEditImage(true);
    setEdit(false);
  };
  useEffect(() => {
    setOpenBackDrop(true);
    const fetch = async () => {
      const data = await getAllEvaluationsByStepIdAndType(
        evaluationType,
        stepId
      );
      setOpenBackDrop(false);
      if (data.length > 0) {
        setDataEvaluation(data[0]);
        setEditImage(false);
        setEvaluationId(data[0].id);
        setLift(data[0].lift);
        setPull(data[0].pull);
        setPush(data[0].push);
        setLoad(data[0].load);
        setRightHand(data[0].right_hand);
        setLeftHand(data[0].left_hand);
        setRightElbow(data[0].right_elbow);
        setLeftElbow(data[0].left_elbow);
        setRightShoulder(data[0].right_shoulder);
        setLeftShoulder(data[0].left_shoulder);
        setNeck(data[0].neck);
        setLegs(data[0].legs);
        setBack(data[0].back);
        setNpr(data[0].npr);
        setEdit(false);
        setStepFrequencyData(data[0].time_activity);
        setButtonState("edit");
        setButtonTitle("Editar Avaliação");
      }
    };
    fetch();
    setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId, refresh]);

  const handleDelete = () => {
    setOpenDelete(true);
  };

  const setRefreshEvaluation = () => {
    setRefresh(true);
    setEvaluationId("");
    setEdit(true);
    setEditImage(true);
  };

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);
      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (stepId === "" || stepFrequency === "" || stepPosition === "") {
      setMessage("Favor selecionar a frequência e o tempo");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        var res;
        if (buttonState === "create") {
          res = await createStepEvaluation({
            evaluationType,
            rightHandResults: rightHandResults.toString(),
            leftHandResults: leftHandResults.toString(),
            rightElbowResults: rightElbowResults.toString(),
            leftElbowResults: leftElbowResults.toString(),
            rightShoulderResults: rightShoulderResults.toString(),
            leftShoulderResults: leftShoulderResults.toString(),
            legsResults: legsResults.toString(),
            neckResults: neckResults.toString(),
            backResults: backResults.toString(),
            totalResults: totalResults.toString(),
            lift: lift.toString(),
            pull: pull.toString(),
            load: load.toString(),
            push: push.toString(),
            activityId,
            companyId,
            gheId,
            jobPositionId,
            sectorId,
            areaId,
            workstationId,
            stepId,
            leftHandExtension,
            leftHandFlexion,
            leftHandUlnar,
            leftHandRadial,
            rightHandExtension,
            rightHandFlexion,
            rightHandUlnar,
            rightHandRadial,
            leftHandGripStrength,
            leftHandGripDuration,
            leftHandGripFrequency,
            leftHandHoldStrength,
            leftHandHoldDuration,
            leftHandHoldFrequency,
            leftHandClampStrength,
            leftHandClampDuration,
            leftHandClampFrequency,
            rightHandGripStrength,
            rightHandGripDuration,
            rightHandGripFrequency,
            rightHandHoldStrength,
            rightHandHoldDuration,
            rightHandHoldFrequency,
            rightHandClampStrength,
            rightHandClampDuration,
            rightHandClampFrequency,
            leftElbowRotation,
            rightElbowRotation,
            leftElbowExtension,
            rightElbowExtension,
            leftElbowStrength,
            leftElbowDuration,
            leftElbowFrequency,
            rightElbowStrength,
            rightElbowDuration,
            rightElbowFrequency,
            bothElbowStrength,
            bothElbowDuration,
            bothElbowFrequency,
            leftShoulderFlexion,
            rightShoulderFlexion,
            leftShoulderAbduction,
            rightShoulderAbduction,
            leftShoulderElevation,
            rightShoulderElevation,
            leftShoulderExtension,
            rightShoulderExtension,
            leftShoulderStrength,
            leftShoulderDuration,
            leftShoulderFrequency,
            rightShoulderStrength,
            rightShoulderDuration,
            rightShoulderFrequency,
            bothShoulderStrength,
            bothShoulderDuration,
            bothShoulderFrequency,
            neckFlexion,
            neckSideFlexion,
            neckExtension,
            neckRotation,
            neckStrength,
            neckDuration,
            neckFrequency,
            backFlexion,
            backRotation,
            backSideFlexion,
            backExtension,
            backNoSupport,
            backStrength,
            backDuration,
            backFrequency,
            legsCrouching,
            legsKneeling,
            legsNoSupport,
            legsStrength,
            legsDuration,
            legsFrequency,
            vibration,
            lowTemperature,
            softTissueCompression,
            impactStress,
            gloveIssue,
            timeActivity: timeActivity.toString(),
          });
        } else {
          res = await editStepEvaluation({
            evaluationId,
            evaluationType,
            rightHandResults: rightHandResults.toString(),
            leftHandResults: leftHandResults.toString(),
            rightElbowResults: rightElbowResults.toString(),
            leftElbowResults: leftElbowResults.toString(),
            rightShoulderResults: rightShoulderResults.toString(),
            leftShoulderResults: leftShoulderResults.toString(),
            legsResults: legsResults.toString(),
            neckResults: neckResults.toString(),
            backResults: backResults.toString(),
            totalResults: totalResults.toString(),
            lift: lift.toString(),
            pull: pull.toString(),
            load: load.toString(),
            push: push.toString(),
            activityId,
            companyId,
            gheId,
            jobPositionId,
            sectorId,
            areaId,
            workstationId,
            stepId,
            leftHandExtension,
            leftHandFlexion,
            leftHandUlnar,
            leftHandRadial,
            rightHandExtension,
            rightHandFlexion,
            rightHandUlnar,
            rightHandRadial,
            leftHandGripStrength,
            leftHandGripDuration,
            leftHandGripFrequency,
            leftHandHoldStrength,
            leftHandHoldDuration,
            leftHandHoldFrequency,
            leftHandClampStrength,
            leftHandClampDuration,
            leftHandClampFrequency,
            rightHandGripStrength,
            rightHandGripDuration,
            rightHandGripFrequency,
            rightHandHoldStrength,
            rightHandHoldDuration,
            rightHandHoldFrequency,
            rightHandClampStrength,
            rightHandClampDuration,
            rightHandClampFrequency,
            leftElbowRotation,
            rightElbowRotation,
            leftElbowExtension,
            rightElbowExtension,
            leftElbowStrength,
            leftElbowDuration,
            leftElbowFrequency,
            rightElbowStrength,
            rightElbowDuration,
            rightElbowFrequency,
            bothElbowStrength,
            bothElbowDuration,
            bothElbowFrequency,
            leftShoulderFlexion,
            rightShoulderFlexion,
            leftShoulderAbduction,
            rightShoulderAbduction,
            leftShoulderElevation,
            rightShoulderElevation,
            leftShoulderExtension,
            rightShoulderExtension,
            leftShoulderStrength,
            leftShoulderDuration,
            leftShoulderFrequency,
            rightShoulderStrength,
            rightShoulderDuration,
            rightShoulderFrequency,
            bothShoulderStrength,
            bothShoulderDuration,
            bothShoulderFrequency,
            neckFlexion,
            neckSideFlexion,
            neckExtension,
            neckRotation,
            neckStrength,
            neckDuration,
            neckFrequency,
            backFlexion,
            backRotation,
            backSideFlexion,
            backExtension,
            backNoSupport,
            backStrength,
            backDuration,
            backFrequency,
            legsCrouching,
            legsKneeling,
            legsNoSupport,
            legsStrength,
            legsDuration,
            legsFrequency,
            vibration,
            lowTemperature,
            softTissueCompression,
            impactStress,
            gloveIssue,
            timeActivity: timeActivity.toString(),
          });
        }
        if (res) {
          for (const file of files) {
            setOpenBackDrop(true);
            await uploadEvaluationImage(file, res.id);
          }
        }
        setMessage("Avaliação editada com sucesso");
        setSeverity("success");
        setOpen(true);
        setRefresh(true);
        setOpenBackDrop(false);
      } catch (err) {
        console.warn(err);
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
        setOpenBackDrop(false);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (evaluationId !== "" && evaluationId !== undefined) {
        const data = await getAllEvaluationsImages(evaluationId);
        setImage(data[0]);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationId, stepId, refresh]);

  return (
    <>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <GridContainer>
            {!edit && (
              <>
                <GridItem xs={12} sm={4} md={4}>
                  <BriefAndBestNPREvaluation totalResults={npr} />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <HumanBody
                    title={""}
                    rightHand={rightHand}
                    leftHand={leftHand}
                    rightShoulder={rightShoulder}
                    leftShoulder={leftShoulder}
                    rightElbow={rightElbow}
                    leftElbow={leftElbow}
                    back={back}
                    legs={legs}
                    neck={neck}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  {image && <StepEvaluationImageSide image={image} />}
                </GridItem>
              </>
            )}
            <TextField
              style={{ display: "none" }}
              value={evaluationType}
              disabled
              variant="outlined"
              id="type"
              label="Tipo da avaliação"
              name="type"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            {!edit && <TabelaRisco />}
            {edit && !openBackDrop && <BriefAndBest />}
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled
                value={
                  edit
                    ? getFrequency(stepFrequency)
                    : getFrequency(stepFrequencyData)
                }
                variant="outlined"
                id="step_frequency"
                label="Tempo por semana"
                name="step_frequency"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled
                value={edit ? rightHandResults : rightHand}
                variant="outlined"
                id="right_hand"
                label="Mão direita"
                name="right_hand"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={edit ? leftHandResults : leftHand}
                disabled
                variant="outlined"
                id="left_hand"
                label="Mão esquerda"
                name="left_hand"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={edit ? rightElbowResults : rightElbow}
                disabled
                variant="outlined"
                id="right_elbow"
                label="Cotovelo direito"
                name="right_elbow"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={edit ? leftElbowResults : leftElbow}
                disabled
                variant="outlined"
                id="left_elbow"
                label="Cotovelo esquerdo"
                name="left_elbow"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={edit ? rightShoulderResults : rightShoulder}
                disabled
                variant="outlined"
                id="right_shoulder"
                label="Ombro direito"
                name="right_shoulder"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={edit ? leftShoulderResults : leftShoulder}
                disabled
                variant="outlined"
                id="left_shoulder"
                label="Ombro esquerdo"
                name="left_shoulder"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={edit ? neckResults : neck}
                disabled
                variant="outlined"
                id="neck"
                label="Pescoço"
                name="neck"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled
                value={edit ? backResults : back}
                variant="outlined"
                id="back"
                label="Costas"
                name="back"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled
                value={edit ? legsResults : legs}
                variant="outlined"
                id="legs"
                label="Pernas"
                name="legs"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled={!edit ? true : false}
                value={lift}
                variant="outlined"
                id="lift"
                label="Levantar"
                name="lift"
                onChange={(event) => setLift(event.target.value)}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled={!edit ? true : false}
                value={pull}
                variant="outlined"
                id="pull"
                label="Puxar"
                name="pull"
                onChange={(event) => setPull(event.target.value)}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled={!edit ? true : false}
                value={load}
                variant="outlined"
                id="load"
                label="Carregar"
                name="load"
                onChange={(event) => setLoad(event.target.value)}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                disabled={!edit ? true : false}
                value={push}
                variant="outlined"
                id="push"
                label="Empurrar"
                name="push"
                onChange={(event) => setPush(event.target.value)}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>

            {!edit && (
              <>
                <GridItem xs={12} sm={4} md={4}></GridItem>

                <GridItem xs={12} sm={4} md={4}>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <RegularButton
                      disabled={isReader}
                      color="warning"
                      variant="contained"
                      onClick={handleEdit}
                    >
                      Editar Avaliação
                    </RegularButton>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <RegularButton
                      disabled={isReader}
                      color="rose"
                      variant="contained"
                      onClick={handleDelete}
                    >
                      Excluir Avaliação
                    </RegularButton>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <RegularButton
                      disabled={isReader}
                      color="warning"
                      variant="contained"
                      onClick={handleEditImage}
                    >
                      Editar Imagens
                    </RegularButton>
                  </div>
                </GridItem>
              </>
            )}
          </GridContainer>
          <GridContainer>
            {buttonState === "create" && (
              <>
                {preview.length > 0 &&
                  preview
                    .filter((preview) => preview.blob !== null)
                    .map((preview) => {
                      return (
                        <ImageCard
                          key={preview.pos}
                          preview={preview}
                          removeFile={removeFile}
                        />
                      );
                    })}
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <RegularButton
                      disabled={isReader}
                      variant="contained"
                      color="primary"
                      component="label"
                    >
                      + Adicionar imagens
                      <input
                        onChange={handleUploadMultipleFiles}
                        type="file"
                        className="form-control"
                        hidden
                      />
                    </RegularButton>
                  </div>
                </GridItem>
              </>
            )}
            {edit && (
              <>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      margin: "20px",
                      textAlign: "center",
                    }}
                  >
                    <RegularButton
                      disabled={isReader}
                      color={buttonState === "edit" ? "warning" : "success"}
                      type="submit"
                      variant="contained"
                    >
                      {buttonTitle}
                    </RegularButton>
                  </div>
                </GridItem>
              </>
            )}
          </GridContainer>
        </form>
        <div style={{ marginTop: "20px" }}></div>
        {editImage && (
          <StepEvaluationImage
            setOpenBackDrop={setOpenBackDrop}
            setEditImage={setEditImage}
            step_evaluation_id={evaluationId}
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOutRefresh={setRefresh}
          />
        )}
      </CardBody>
      <DeleteDialogEvaluation
        open={openDelete}
        setOpen={setOpenDelete}
        deleteFunction={deleteEvaluation}
        id={evaluationId}
        setRefresh={setRefreshEvaluation}
        setOpenMessage={setOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
      />
    </>
  );
}

const getFrequency = (value) => {
  const times = [
    { time: 0.4, message: "Menos de 4 horas" },
    { time: 0.8, message: "De 4 a 19 horas" },
    { time: 1, message: "De 20 a 40 horas" },
    { time: 1.25, message: "Mais de 40 horas" },
    { time: "0.4", message: "Menos de 4 horas" },
    { time: "0.8", message: "De 4 a 19 horas" },
    { time: "1", message: "De 20 a 40 horas" },
    { time: "1.25", message: "Mais de 40 horas" },
  ];
  const result = times.find((el) => el.time === value);
  return result?.message ?? "";
};
